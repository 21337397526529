<template>
  <section id="calculator">
    <div id="inside">
      <h1>SPRAWDŹ PRZEWIDYWANĄ KWOTĘ INWESTYCJI</h1>
      <h2>Kalkulator inwestycji</h2>
      <form onsubmit="return false">
        <label for="FLName">Imię i nazwisko</label>
        <input type="text" id="FLName" v-model="FLName" required />
        <label for="Email">Email</label>
        <input type="email" id="Email" v-model="Email" required />
        <label for="Phone">Numer telefonu</label>
        <input
          type="tel"
          id="Phone"
          name="Phone"
          v-model="Phone"
          pattern="[0-9]{3}[0-9]{3}[0-9]{3}"
          required
        />
        <label for="City">Miejscowość</label>
        <input type="text" id="City" v-model="City" required />
        <label for="Base">Rodzaj podłoża</label>
        <select id="Base" v-model="Base">
          <option>Deskowanie płyta, OSB</option>
          <option>Membrana paroprzepuszczalna</option>
          <option>Ściana, cegła, pustak</option>
          <option>Blacha</option>
        </select>
        <label for="Foam">Wybór piany</label>
        <select id="Foam" v-model="Foam">
          <option>Piana Otwartokomórkowa QuadFoam500</option>
          <option>Piana Otwartokomórkowa IQFoam+</option>
          <option>Piana Zamkniętokomórkowa</option>
        </select>
        <label for="Area">Powierzchnia do izolacji [&#13217;]</label>
        <input
          type="number"
          value="0"
          min="0"
          id="Area"
          v-model="Area"
          required
        />
        <label for="Thickness">Grubość izolacji [cm]</label>
        <input
          type="number"
          value="0"
          min="0"
          id="Thickness"
          v-model="Thickness"
          required
        />
        <label for="Distance">Przybliżona odległość do Jaworzna [km]</label>
        <input
          type="number"
          value="0"
          min="0"
          id="Distance"
          v-model="Distance"
          required
        />
        <div>
          <input
            type="checkbox"
            id="company"
            name="company"
            v-model="company"
          />
          <label for="company">Firma</label>
        </div>
        <div>
          <input
            type="checkbox"
            id="agreement1"
            name="agreement1"
            v-model="agreement1"
            required
          />
          <label for="agreement1"
            >Wyrażam zgodę na przetwarzanie i przekazywanie moich danych Wyrażam
            zgodę na przetwarzanie i przekazywanie przez Purtech Izolacje ul.
            Kisielewskiego 8/3 w Sosnowcu moich danych osobowych w celach
            marketingowych i informacyjnych zgodnie z Ustawą z dnia 29 sierpnia
            1997r. o ochronie danych osobowych (Dz. U. z 2002r. nr 101, poz. 926
            z późn. zm.). Zgoda na przetwarzanie danych jest wymagana</label
          >
        </div>
        <div>
          <input
            type="checkbox"
            id="agreement2"
            name="agreement2"
            v-model="agreement2"
            required
          />
          <label for="agreement2"
            >Wyrażam zgodę na otrzymanie informacji handlowej celem odpowiedzi
            na przesłane zapytanie. Wyrażam zgodę na otrzymywanie informacji
            handlowych od Purtech Izolacje ul. Kisielewskiego 8/3 w Sosnowcu w
            rozumieniu ustawy z dnia 18 lipca 2002 r. o świadczeniu usług drogą
            elektroniczną, z wykorzystaniem środków komunikacji elektronicznej,
            w szczególności na przesyłanie przez Purtech Poland Sp. z o.o.
            informacji handlowych na podany adres poczty elektronicznej lub
            numer telefonu. Zgoda na otrzymanie informacji handlowej jest
            wymagana</label
          >
        </div>
        <div>
          <input
            type="checkbox"
            id="agreement3"
            name="agreement3"
            v-model="agreement3"
            required
          />
          <label for="agreement3"
            >Wyrażam zgodę na przetwarzanie danych osobowych w celu odpowiedzi
            na przesłaną wiadomość. Wyrażam zgodę na przetwarzanie danych
            osobowych w celu odpowiedzi na przesłaną wiadomość. Zgoda na
            przetwarzanie danych w celu otrzymania odpowiedzi jest
            wymagana</label
          >
        </div>
        <div id="summary">
          <input type="submit" value="Oblicz" v-on:click="Calc" />
          <div id="result">
            {{ result }}
          </div>
        </div>
      </form>
      <h3>
        Uprzejmie informujemy, że:
        <br /><br />
        a) Purtech Izolacje ul. Kisielewskiego 8/3 w Sosnowcu stanie się
        Administratorem Pani/Pana danych osobowych,
        <br /><br />
        b) dane osobowe będą przetwarzane w celu odpowiedzi na Pana/Pani
        zapytanie, a w przypadku wyrażenia zgody na przetwarzanie danych w
        celach marketingowych - wykorzystamy je dla przedstawienia oferty
        naszych produktów i usług bądź informacji o naszej działalności,
        <br /><br />
        c) Przysługuje Pani/Panu prawo dostępu do treści danych oraz ich
        sprostowania, usunięcia lub ograniczenia przetwarzania, a także prawo
        sprzeciwu, zażądania zaprzestania przetwarzania i przenoszenia danych,
        jak również prawo do cofnięcia zgody w dowolnym momencie oraz prawo do
        wniesienia skargi do organu nadzorczego Prezesa Urzędu Ochrony Danych
        Osobowych.,
        <br /><br />
        d) podanie danych osobowych jest dobrowolne, jednak brak ich podania
        uniemożliwi nam odpowiedź na Pana/Pani zapytanie złożone za
        pośrednictwem stron internetowej
        <br /><br />
        e) przysługuje Pani/Panu prawo wniesienia sprzeciwu wobec przetwarzania
        swoich danych osobowych,
        <br /><br />
        f) dane osobowe będą przetwarzane w warunkach zapewniających ich ochronę
        zgodnie z obowiązującymi przepisami prawa,
        <br /><br />
        g) dane nie będą podlegały zautomatyzowanemu podejmowaniu decyzji, w tym
        profilowaniu,
        <br /><br />
        W zakresie określonym w pkt. a)-f) powyżej oświadczenia, żądania lub
        pytania należy zgłaszać w formie elektronicznej na adres
        biuro@purtechizolacje.pl lub pisemnej na adres Purtech Izolacje ul.
        Kisielewskiego 8/3 w Sosnowcu.
      </h3>
    </div>
  </section>
</template>

<script>
export default {
  name: "Calculator",
  data() {
    return {
      FLName: "",
      Email: "",
      Phone: "",
      City: "",
      Base: "Deskowanie płyta, OSB",
      Foam: "Piana Otwartokomórkowa QuadFoam500",
      Area: 0,
      Thickness: 0,
      Distance: 0,
      company: false,
      agreement1: false,
      agreement2: false,
      agreement3: false,
      result: "Wypełnij formularz",
      materialCost: 0,
    };
  },
  methods: {
    Calc: function () {
      if (
        this.FLName != "" &&
        this.Email != "" &&
        this.Phone != "" &&
        this.City != "" &&
        this.Base != "" &&
        this.Foam != "" &&
        this.Area > 0 &&
        this.Thickness > 0 &&
        this.Distance > 0 &&
        this.agreement1 == true &&
        this.agreement2 == true &&
        this.agreement3 == true
      ) {
        let FoamCost = 0;

        switch (this.Foam) {
          case "Piana Otwartokomórkowa QuadFoam500":
            FoamCost = 4.2;
            break;

          case "Piana Otwartokomórkowa IQFoam+":
            FoamCost = 3.5;
            break;

          case "Piana Zamkniętokomórkowa":
            FoamCost = null;
            break;
        }

        if (FoamCost != null && this.Thickness > 10 && this.Area > 80) {
          this.materialCost = FoamCost * this.Thickness;

          if (this.Area >= 80 && this.Area < 100) this.materialCost *= 1.08;

          if (this.Area > 200) this.materialCost *= 0.98;

          this.result =
            (
              (this.materialCost * this.Area + this.Distance * 2) *
              (this.company == true ? 1.23 : 1.08)
            ).toFixed(2) + "zł";
        } else
          this.result =
            "Prosimy o kontakt w związku z niestandardową inwestycją";

        this.SendMail();
      } else this.result = "Wypełnij formularz";
    },
    SendMail: function () {
      fetch(
        "https://purtechizolacje.pl/api/send.php?name=" +
          this.FLName +
          "&phone=" +
          this.Phone +
          "&city=" +
          this.City +
          "&base=" +
          this.Base +
          "&foam=" +
          this.Foam +
          "&area=" +
          this.Area +
          "&thickness=" +
          this.Thickness +
          "&distance=" +
          this.Distance +
          "&company=" +
          this.company +
          "&email=" +
          this.Email +
          "&coast=" +
          this.result,
        {
          method: "GET",
        }
      );
    },
  },
};
</script>

<style scoped>
section#calculator {
  display: flex;
  flex-direction: column;
  align-items: center;
}

section#calculator div#inside {
  width: 60%;
  margin: 120px 0 0 0;
}

section#calculator div#inside h1 {
  font-size: 1.4rem;
  color: #6ba421;
  margin: 60px 0 30px 0;
  font-weight: 600;
}

section#calculator div#inside h2 {
  font-size: 2rem;
  line-height: 2.4rem;
  margin: 0 0 30px 0;
  font-weight: 600;
  width: 70%;
}

section#calculator div#inside h3 {
  font-size: 1rem;
  margin: 0 0 30px 0;
  width: 70%;
  line-height: 1.2rem;
}

section#calculator div#inside h3 b {
  font-weight: 600;
}

section#calculator div#inside form {
  display: flex;
  flex-direction: column;
}

section#calculator div#inside form label {
  font-size: 1rem;
  margin: 20px 0 7px 0;
  color: #444;
  line-height: 1.4rem;
}

section#calculator div#inside form input:invalid:focus {
  outline-style: solid;
  outline-color: #e74c3c;
}

section#calculator div#inside form input[type="text"] {
  width: 60%;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
  background-color: #fff;
  outline-width: 0;
  padding: 6px 10px 6px 10px;
}

section#calculator div#inside form input[type="number"] {
  width: 60%;
  height: 32px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
  background-color: #fff;
  outline-width: 0;
  padding: 0px 10px 0px 10px;
}

section#calculator div#inside form input[type="email"] {
  width: 60%;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
  background-color: #fff;
  outline-width: 0;
  padding: 6px 10px 6px 10px;
}

section#calculator div#inside form input[type="tel"] {
  width: 60%;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
  background-color: #fff;
  outline-width: 0;
  padding: 6px 10px 6px 10px;
}

section#calculator div#inside form select {
  width: 60%;
  padding: 6px 10px 6px 10px;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  border: 1px solid #ccc;
  background-color: #fff;
}

section#calculator div#inside form div {
  margin: 10px 0 10px 0;
}

section#calculator div#inside form div:last-child {
  margin: 10px 0 30px 0;
}

section#calculator div#inside form div#summary {
  display: flex;
  flex-direction: row;
  width: 60%;
}

section#calculator div#inside form div#summary div#result {
  width: 50%;
  height: 80px;
  background-color: #6ba421;
  margin: 0;
  border-radius: 0 2px 2px 0;
  text-align: center;
  line-height: 1.4rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
}

section#calculator div#inside form div#summary input[type="submit"] {
  width: 50%;
  height: 80px;
  font-size: 1.8rem;
  border: none;
  color: #fff;
  background-color: #6ba421;
  border-left: 1px solid #fff;
  margin: 0;
  border-radius: 2px 0 0 2px;
  cursor: pointer;
  transition: all 0.3s ease;
}

section#calculator div#inside form div#summary input[type="submit"]:hover {
  background-color: #5a8310;
}

@media only screen and (max-width: 1280px) {
  section#calculator div#inside {
    width: 80%;
    margin: 60px 0 0 0;
  }

  section#calculator div#inside h2 {
    font-size: 1.6rem;
    line-height: 2rem;
    width: 100%;
  }

  section#calculator div#inside h3 {
    width: 100%;
  }

  section#calculator div#inside form input[type="text"],
  input[type="email"],
  input[type="tel"],
  input[type="number"] {
    width: 100% !important;
  }

  section#calculator div#inside form select {
    width: 100%;
  }

  section#calculator div#inside form div#summary {
    width: 100%;
  }
}
</style>
